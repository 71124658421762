import { graphql, PageProps } from "gatsby";
import { lazy, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";
import { uppercaseFirstLetter } from "@/base/helpers/uppercaseFirstLetter";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import MainLayout from "@/components/layout/Main";
import ScientificUpdateCategoryList from "@/components/sections/ScientificUpdateCategoryList";
import * as styles from "@/components/sections/ScientificUpdateCategoryList/styles.module.scss";
import HeaderScientificUpdate from "@/components/sections/ScientificUpdateList/HeaderScientificUpdate";
import LazyIntersectionObserver from "../elements/LazyIntersectionObserver/LazyIntersectionObserver";
import ResourceList from "../sections/Resources/ResourceList";

type TCategories = {
  edges: {
    node: {
      id: string;
      name: string;
      content: string;
    };
  }[];
};

type PageData = {
  header: StoryblokStory;
  footer: StoryblokStory;
  categories: TCategories;
};

type PageContext = {
  category: CategoryType;
  categoryName: string;
  dataSourceName: "resources";
};

function ResourcesCategory({
  pageContext,
  data,
}: PageProps<PageData, PageContext>) {
  const totalAmount = useRef(0);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [page, setPage] = useState(1);

  const { category, categoryName, dataSourceName } = pageContext;
  const { footer, header, categories } = data;

  const filterResourcesByCategory = (
    categories: TCategories
  ): ResourceComponent[] => {
    return JSON.parse(
      categories.edges?.find(({ node }) =>
        node.name.includes(uppercaseFirstLetter(dataSourceName))
      )?.node.content as string
    ).body.find(
      (node: { component: string }) => node.component === "section_resources"
    )[category];
  };

  const amountPerPage = process.env.GATSBY_SU_OFFSET
    ? parseInt(process.env.GATSBY_SU_OFFSET, 10)
    : 9;

  const labelByCategory =
    categoryName === "Books" ? "Reports & books" : categoryName;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;

  const seo = {
    title: `${uppercaseFirstLetter(dataSourceName)} - ${categoryName}`,
    description: `${uppercaseFirstLetter(dataSourceName)} - ${categoryName}`,
    url: `/${dataSourceName}/${categoryName}`,
  };

  const LazyNewsletter = lazy(() => import("@/components/sections/Newsletter"));

  return (
    <MainLayout
      header={parsedHeader}
      footer={parsedFooter}
      seo={seo}
      type="website"
    >
      <HeaderScientificUpdate
        className={styles.categoryWrapper}
        setPage={(page: number) => setPage(page + 1)}
        dataSourceCategory={dataSourceName}
      >
        <div className={styles.headerTextContainer}>
          <LinkComponent type="internal" url="/resources/">
            {dataSourceName.toUpperCase()} /
          </LinkComponent>
          <Heading level={1}>Tag: “{categoryName}”</Heading>
        </div>
      </HeaderScientificUpdate>
      <ScientificUpdateCategoryList
        category={categoryName}
        page={page}
        amountPerPage={amountPerPage}
        totalAmount={totalAmount}
        tag={dataSourceName}
      />

      <ResourceList
        title={labelByCategory}
        list={filterResourcesByCategory(categories)}
      />

      <LazyIntersectionObserver
        Component={LazyNewsletter}
        inView={inView}
        ref={ref}
      />
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query ResourcesCategoryPageQuery {
    header: storyblokEntry(field_component: { eq: "template_header" }) {
      content
    }
    footer: storyblokEntry(field_component: { eq: "template_footer" }) {
      content
    }
    categories: allStoryblokEntry(
      filter: { field_component: { eq: "template_page" } }
    ) {
      edges {
        node {
          id
          name
          content
        }
      }
    }
  }
`;

export default ResourcesCategory;
